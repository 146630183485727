const ENV = {
	isDev: process.env.NODE_ENV !== "production",
	isProd:
		process.env.NODE_ENV === "production" &&
		window.location.host !== "staging.tagnology.co",
};
const Config = {
	NAME: "Amplify",
	BASE_URL:
		process.env.NODE_ENV !== "production"
			? "http://localhost:3000"
			: `${window.location.origin}/api`,
	LINE_OA: "https://line.me/R/ti/p/@081nmahy",
	GA_ID: "G-W8T78TKDT8",
	IG_APP_ID: "695354621316566",
	SHOPLINE_CLIENT_ID:
		process.env.NODE_ENV !== "production"
			? "22e2543d17595e40bbd7dc5611b6471aade70377f3a186161fb9856d77f887c1"
			: "325514733d7cce4d2340d33c710924532460b9a346a1c5de1eef2d46e2176fdb",
	CYBERBIZ_CLIEND_ID:
		process.env.NODE_ENV !== "production"
			? "qFINcC3Au4nMo7S2BBq7DJi2gLk2jXdhTbSzbm3l74g"
			: "qFINcC3Au4nMo7S2BBq7DJi2gLk2jXdhTbSzbm3l74g",
};
export { ENV };
export default Config;
