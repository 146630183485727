import Config from "constants/config";
import { get } from "lodash-es";
import Axios from "axios";
import { VarifyCodeType } from "constants/general";

Axios.defaults.withCredentials = true;

const UserAPI = {
	/**
	 * @param {string} body.email
	 * @param {string} body.password
	 */
	Login: async (body = {}) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/login`,
			body
		);
		return data;
	},
	/**
	 * using cookies
	 */
	Logout: async () => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/logout`
		);
		return data;
	},
	/**
	 * @param {string} body.email
	 * @param {string} body.code
	 * @param {string} body.password
	 */
	Register: async (body = {}) => {
		try {
			const { data = {} } = await Axios.post(
				`${Config.BASE_URL}/user/register`,
				body
			);
			return data;
		} catch (error) {
			throw get(error, "response.data", {}) || error;
		}
	},
	/**
	 * @param {string} email
	 * @param {enum} type
	 */
	SendCode: async (email, type = VarifyCodeType.Register) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/sendCode`,
			{
				email,
				type,
			}
		);
		return data;
	},
	/**
	 * @param {string} body.email
	 * @param {string} body.password
	 * @param {string} body.code
	 */
	Reset: async (body = {}) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/reset`,
			body
		);
		return data;
	},
	/**
	 * using cookies
	 */
	GetUserInfo: async () => {
		const { data = {} } = await Axios.get(
			`${Config.BASE_URL}/user/getUserInfo`
		);
		return get(data, "userInfo", null);
	},
	AuthIGAccount: async (code) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/ig/auth`,
			{
				code,
				redirectUrl: `${window.location.origin}${window.location.pathname}`,
			}
		);
		return data;
	},

	GetUserDetail: async () => {
		const { data = {} } = await Axios.get(
			`${Config.BASE_URL}/user/getUserDetail`
		);
		return data;
	},

	SetUserDetail: async (detail = {}) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/setUserDetail`,
			{
				detail,
			}
		);
		return data;
	},

	UploadPrivateImage: async (source, fileName) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/uploadPrivateImage`,
			{
				source,
				fileName,
			}
		);
		return get(data, "url", null);
	},

	GetPrivateImgUrl: async (imageUrl) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/user/getPrivateImgUrl`,
			{
				imageUrl,
			}
		);
		return get(data, "url", null);
	},
};
export default UserAPI;
