import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export default function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: createRootReducer(history),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(routerMiddleware(history), thunk),
		preloadedState,
		devTools: process.env.NODE_ENV !== "production",
	});

	return store;
}
