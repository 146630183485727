import i18n from "i18next";
import { initReactI18next, Trans } from "react-i18next";
import { i18Ns } from "constants/general";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const apiKey = "dndDPBAyY3RMbyE84m5s6w";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: "zh",
		fallbackLng: "zh",
		ns: [
			"common",
			"campaigns",
			"myCampaigns",
			"help",
			"notification",
			"accounts",
			"wallet",
		],
		defaultNS: "common",
		supportedLngs: ["en", "zh", "ja"],
		backend: {
			loadPath: loadPath,
		},
		// debug: ENV.isDev,
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export { i18n, i18Ns, Trans };
