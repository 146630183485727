const ErrorType = {
	default: 0,
	unauthorized: 401,
	forbidden: 403,
	notFound: 404,
	internalError: 500,
	noService: 503,
};

const Language = [
	{
		name: "English",
		value: "en",
	},
	{
		name: "中文",
		value: "zh",
	},
	{
		name: "日本語",
		value: "ja",
	},
];

const i18Ns = {
	common: "common",
	campaigns: "campaigns",
	myCampaigns: "myCampaigns",
	help: "help",
	notification: "notification",
	accounts: "accounts",
	wallet: "wallet",
};

const Paths = {
	Root: "/",
	Redirect: "/",
	Dashboard: "/dashboard",
	AlconDashboard: "/alconDashboard",
	// User
	Login: "/login",
	Register: "/register",
	Auth: "/auth",
	ResetPassword: "/resetPassword",
	Accounts: "/accounts",
	Profile: "/profile",
	Setting: "/profile/setting",
	Notification: "/notification",
	Wallet: "/wallet",
	Verification: "/verification",
	// Campaign
	Campaigns: "/campaigns",
	MyCampaigns: "/myCampaigns",
	Policy: "/policy",
	// Prize
	Prize: "/prize",
	// Common
	Help: "/help",
	Assistant: "/assistant",
	Terms: "/terms",
	// Cyberbiz
	CyberbizLogin: "/cyberbiz/login",
	CyberbizCallback: "/cyberbiz/callback",
	// callback
	Callback: "/callback",
	ShoplineCallback: "/shopline/callback",
};

const Pages = {
	Campaigns: {
		label: "campaigns",
		path: Paths.Campaigns,
	},
	MyCampaigns: {
		label: "myCampaigns",
		path: Paths.MyCampaigns,
	},
	Profile: {
		label: "profile",
		path: Paths.Profile,
	},
	Wallet: {
		label: "wallet",
		path: Paths.Wallet,
	},
	Notification: {
		label: "notification",
		path: Paths.Notification,
	},
	ContactUs: {
		label: "contactUs",
	},
	Assistant: {
		label: "assistant",
		path: Paths.Assistant,
	},
	Policy: {
		label: "policy",
		path: Paths.Policy,
	},
	Terms: {
		label: "Terms",
		path: Paths.Terms,
	},
	Dashboard: {
		label: "dashboard",
		path: Paths.Dashboard,
	},
	Accounts: {
		label: "accounts",
		path: Paths.Accounts,
	},
	Help: {
		label: "help",
		path: Paths.Help,
	},
};

const Countries = [
	{
		label: "台灣",
		value: "Taiwan",
		districts: [
			"臺北市",
			"新北市",
			"桃園市",
			"臺中市",
			"臺南市",
			"高雄市",
			"新竹縣",
			"苗栗縣",
			"彰化縣",
			"南投縣",
			"雲林縣",
			"嘉義縣",
			"屏東縣",
			"宜蘭縣",
			"花蓮縣",
			"臺東縣",
			"澎湖縣",
			"金門縣",
			"連江縣",
			"基隆縣",
			"新竹市",
			"嘉義市",
		],
	},
	// {
	// 	label: "日本",
	// 	value: "Japan",
	// },
	// {
	// 	label: "대한민국",
	// 	value: "Korea",
	// },
];

const InterestOptions = [
	{
		label: "美妝保養",
		value: 0,
	},
	{
		label: "個人用品",
		value: 1,
	},
	{
		label: "美食料理",
		value: 2,
	},
	{
		label: "汽車機車",
		value: 3,
	},
	{
		label: "零售選物",
		value: 4,
	},
	{
		label: "3C電器",
		value: 5,
	},
	{
		label: "菸酒",
		value: 6,
	},
	{
		label: "百貨用品",
		value: 7,
	},
	{
		label: "運動用品",
		value: 8,
	},
	{
		label: "母嬰產品",
		value: 9,
	},
	{
		label: "時尚精品",
		value: 10,
	},
	{
		label: "隱形眼鏡",
		value: 11,
	},
];

const BrandOptions = [
	{
		id: 1,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/8988bc39066e4042633b77ae43ba2f63.jpg",
		name: "雅詩蘭黛",
	},
	{
		id: 2,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/c28fdc6a7ceebef90e418b669ba8d2dc.jpg",
		name: "heme",
	},
	{
		id: 3,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/f4a7f0c96a395a829584a5c9a0135d8f.jpg",
		name: "安視優",
	},
	{
		id: 4,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/49b814ae284e0582e655b92aa5b65532.jpg",
		name: "Audi",
	},
	{
		id: 5,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/a4b916a0685eda67ad13fe86994f5e2c.jpg",
		name: "7-11",
	},
	{
		id: 6,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/8988bc39066e4042633b77ae43ba2f63.jpg",
		name: "雅詩蘭黛",
	},
	{
		id: 7,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/c28fdc6a7ceebef90e418b669ba8d2dc.jpg",
		name: "heme",
	},
	{
		id: 8,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/f4a7f0c96a395a829584a5c9a0135d8f.jpg",
		name: "安視優",
	},
	{
		id: 9,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/49b814ae284e0582e655b92aa5b65532.jpg",
		name: "Audi",
	},
	{
		id: 10,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/a4b916a0685eda67ad13fe86994f5e2c.jpg",
		name: "7-11",
	},
	{
		id: 11,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/8988bc39066e4042633b77ae43ba2f63.jpg",
		name: "雅詩蘭黛",
	},
	{
		id: 12,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/c28fdc6a7ceebef90e418b669ba8d2dc.jpg",
		name: "heme",
	},
	{
		id: 13,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/f4a7f0c96a395a829584a5c9a0135d8f.jpg",
		name: "安視優",
	},
	{
		id: 14,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/49b814ae284e0582e655b92aa5b65532.jpg",
		name: "Audi",
	},
	{
		id: 15,
		logo: "https://storage.googleapis.com/buckets.tagnology.co/avatar/a4b916a0685eda67ad13fe86994f5e2c.jpg",
		name: "7-11",
	},
];

const Subscriptions = [
	{
		title: "類別、喜歡的品牌有新活動",
		value: 0,
	},
	{
		title: "活動結束",
		value: 1,
	},
	{
		title: "收藏的活動快要到期",
		value: 2,
	},
	{
		title: "收藏的活動預算快到",
		value: 3,
	},
];

const FilterOptions = [
	{
		label: "個人用品",
		value: 0,
	},
	{
		label: "零售選物",
		value: 1,
	},
	{
		label: "美妝保養",
		value: 2,
	},
	{
		label: "美食料理",
		value: 3,
	},
	{
		label: "汽車機車",
		value: 4,
	},
	{
		label: "3 C 電器",
		value: 5,
	},
	{
		label: "菸酒",
		value: 6,
	},
	{
		label: "運動用品",
		value: 7,
	},
	{
		label: "母嬰產品",
		value: 8,
	},
	{
		label: "時尚精品",
		value: 9,
	},
	{
		label: "隱形眼鏡",
		value: 10,
	},
];

const Fields = {
	id: "id",
	coverImage: "coverImage",
	dateFrom: "dateFrom",
	toDate: "toDate",
	price: "price",
	title: "title",
	brand: "brand",
	brandIcon: "brandIcon",
	likes: "likes",
	tags: "tags",
	saved: "saved",
	followed: "followed",
};

const Seeder = [
	{
		[Fields.id]: 1,
		[Fields.coverImage]:
			"https://imgcloud.cardu.com.tw/201910/20210805/message/19/UCardu20210805173974.jpg",
		[Fields.dateFrom]: 1629147873,
		[Fields.toDate]: 1630357473,
		[Fields.price]: 10000,
		[Fields.title]: "國泰 Cube 卡：有他 由我 就足夠",
		[Fields.brand]: "國泰世華銀行",
		[Fields.brandIcon]:
			"https://images.squarespace-cdn.com/content/v1/587a127129687f8f4b9c7686/1586421773189-ZFZEZQZIABYSGT2PKZ93/image-asset.png",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "科技", "金融"],
		[Fields.saved]: true,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 2,
		[Fields.coverImage]:
			"https://noswag.tw/wp-content/uploads/2021/06/20210621014201_94.jpg",
		[Fields.dateFrom]: 1633035873,
		[Fields.toDate]: 1638219873,
		[Fields.price]: 6000,
		[Fields.title]: "WAT 不為什麼？我就是什麼！",
		[Fields.brand]: "WAT COCKTAIL",
		[Fields.brandIcon]:
			"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDVDMxAzbJy1zMthlDnAnJKEQUGTWcX30E2A&usqp=CAU",
		[Fields.likes]: 300,
		[Fields.tags]: ["生活", "時尚", "美食"],
		[Fields.saved]: false,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 3,
		[Fields.coverImage]:
			"https://www.callingtaiwan.com.tw/wp-content/uploads/2019/12/foodpanda-banner-01.png",
		[Fields.dateFrom]: 1627765473,
		[Fields.toDate]: 1632949473,
		[Fields.price]: 60000,
		[Fields.title]: "Foodpanda 品牌招募活動",
		[Fields.brand]: "Foodpanda",
		[Fields.brandIcon]:
			"https://media.cakeresume.com/image/upload/s--hY9y5OQJ--/c_pad,fl_png8,h_400,w_400/v1571369066/au7vxybehtibmj1uedt2.png",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "美食"],
		[Fields.saved]: true,
		[Fields.followed]: true,
	},
	{
		[Fields.id]: 4,
		[Fields.coverImage]:
			"https://noswag.tw/wp-content/uploads/2021/06/20210621014201_94.jpg",
		[Fields.dateFrom]: 1633035873,
		[Fields.toDate]: 1638219873,
		[Fields.price]: 6000,
		[Fields.title]: "WAT 不為什麼？我就是什麼！",
		[Fields.brand]: "WAT COCKTAIL",
		[Fields.brandIcon]:
			"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDVDMxAzbJy1zMthlDnAnJKEQUGTWcX30E2A&usqp=CAU",
		[Fields.likes]: 300,
		[Fields.tags]: ["生活", "時尚", "美食"],
		[Fields.saved]: true,
		[Fields.followed]: true,
	},
	{
		[Fields.id]: 5,
		[Fields.coverImage]:
			"https://imgcloud.cardu.com.tw/201910/20210805/message/19/UCardu20210805173974.jpg",
		[Fields.dateFrom]: 1629147873,
		[Fields.toDate]: 1630357473,
		[Fields.price]: 10000,
		[Fields.title]: "國泰 Cube 卡：有他 由我 就足夠",
		[Fields.brandIcon]:
			"https://images.squarespace-cdn.com/content/v1/587a127129687f8f4b9c7686/1586421773189-ZFZEZQZIABYSGT2PKZ93/image-asset.png",
		[Fields.brand]: "國泰世華銀行",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "科技", "金融"],
		[Fields.saved]: false,
		[Fields.followed]: true,
	},
	{
		[Fields.id]: 6,
		[Fields.coverImage]:
			"https://imgcloud.cardu.com.tw/201910/20210805/message/19/UCardu20210805173974.jpg",
		[Fields.dateFrom]: 1629147873,
		[Fields.toDate]: 1630357473,
		[Fields.price]: 10000,
		[Fields.title]: "國泰 Cube 卡：有他 由我 就足夠",
		[Fields.brand]: "國泰世華銀行",
		[Fields.brandIcon]:
			"https://images.squarespace-cdn.com/content/v1/587a127129687f8f4b9c7686/1586421773189-ZFZEZQZIABYSGT2PKZ93/image-asset.png",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "科技", "金融"],
		[Fields.saved]: false,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 7,
		[Fields.coverImage]:
			"https://noswag.tw/wp-content/uploads/2021/06/20210621014201_94.jpg",
		[Fields.dateFrom]: 1633035873,
		[Fields.toDate]: 1638219873,
		[Fields.price]: 6000,
		[Fields.title]: "WAT 不為什麼？我就是什麼！",
		[Fields.brand]: "WAT COCKTAIL",
		[Fields.brandIcon]:
			"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDVDMxAzbJy1zMthlDnAnJKEQUGTWcX30E2A&usqp=CAU",
		[Fields.likes]: 300,
		[Fields.tags]: ["生活", "時尚", "美食"],
		[Fields.saved]: true,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 8,
		[Fields.coverImage]:
			"https://www.callingtaiwan.com.tw/wp-content/uploads/2019/12/foodpanda-banner-01.png",
		[Fields.dateFrom]: 1627765473,
		[Fields.toDate]: 1632949473,
		[Fields.price]: 60000,
		[Fields.title]: "Foodpanda 品牌招募活動",
		[Fields.brand]: "Foodpanda",
		[Fields.brandIcon]:
			"https://media.cakeresume.com/image/upload/s--hY9y5OQJ--/c_pad,fl_png8,h_400,w_400/v1571369066/au7vxybehtibmj1uedt2.png",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "美食"],
		[Fields.saved]: false,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 9,
		[Fields.coverImage]:
			"https://noswag.tw/wp-content/uploads/2021/06/20210621014201_94.jpg",
		[Fields.dateFrom]: 1633035873,
		[Fields.toDate]: 1638219873,
		[Fields.price]: 6000,
		[Fields.title]: "WAT 不為什麼？我就是什麼！",
		[Fields.brand]: "WAT COCKTAIL",
		[Fields.brandIcon]:
			"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDVDMxAzbJy1zMthlDnAnJKEQUGTWcX30E2A&usqp=CAU",
		[Fields.likes]: 300,
		[Fields.tags]: ["生活", "時尚", "美食"],
		[Fields.saved]: true,
		[Fields.followed]: false,
	},
	{
		[Fields.id]: 10,
		[Fields.coverImage]:
			"https://imgcloud.cardu.com.tw/201910/20210805/message/19/UCardu20210805173974.jpg",
		[Fields.dateFrom]: 1629147873,
		[Fields.toDate]: 1630357473,
		[Fields.price]: 10000,
		[Fields.title]: "國泰 Cube 卡：有他 由我 就足夠",
		[Fields.brandIcon]:
			"https://images.squarespace-cdn.com/content/v1/587a127129687f8f4b9c7686/1586421773189-ZFZEZQZIABYSGT2PKZ93/image-asset.png",
		[Fields.brand]: "國泰世華銀行",
		[Fields.likes]: 25,
		[Fields.tags]: ["生活", "科技", "金融"],
		[Fields.saved]: true,
		[Fields.followed]: false,
	},
];

const Platform = {
	Instagram: "Instagram",
};

const VarifyCodeType = {
	Register: "Register",
	Reset: "Reset",
};

export {
	ErrorType,
	Language,
	Paths,
	Pages,
	Countries,
	InterestOptions,
	BrandOptions,
	Subscriptions,
	Fields,
	Seeder,
	Platform,
	VarifyCodeType,
	i18Ns,
};
