const LocalStorageKey = {
	hiddenGuide: "hiddenGuide",
	language: "language",
	campaignList: "campaignList",
	campaignTop: "campaignTop",
	myCampaignList: "myCampaignList",
	myCampaignTop: "myCampaignTop",
	hasNotification: "hasNotification",
	lastNotifyId: "lastNotifyId",
	currentBrandId: "currentBrandId",
};

export default LocalStorageKey;
