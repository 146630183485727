import Config from "constants/config";
import Axios from "axios";

Axios.defaults.withCredentials = true;

const GAAPI = {
	SendEvent: async (event = {}) => {
		const { data = {} } = await Axios.post(
			`${Config.BASE_URL}/ga/sendEvent`,
			{
				event,
			}
		);
		return data;
	},
};
export default GAAPI;
