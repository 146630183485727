import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom"; // react-router v5
import { SnackbarProvider } from "notistack";
import { GAInitialize, GAPageView } from "utils/GA";
// Constants
import Config from "constants/config";
import { Paths } from "constants/general";
// Components
import Loading from "components/share/Loading";
// Containers
const Navigation = lazy(() => import("components/navigation/Navigation"));
const Login = lazy(() => import("components/user/Login"));
const Register = lazy(() => import("components/user/Register"));
const ResetPassword = lazy(() => import("components/user/ResetPassword"));
const SocialPlatformAuth = lazy(() =>
	import("components/auth/SocialPlatformAuth")
);
const CyberbizLogin = lazy(() => import("components/cyberbiz/Login"));
const CyberbizCallback = lazy(() =>
	import("components/cyberbiz/CyberbizCallback")
);
const Policy = lazy(() => import("components/navigation/Policy"));
// Widget
const Embed = lazy(() => import("components/embed/Embed"));
const Invite = lazy(() => import("components/embed/Invite"));
const LightBox = lazy(() => import("components/embed/LightBox"));
const Prebuy = lazy(() => import("components/embed/Prebuy"));
const EmbedPreview = lazy(() => import("components/embed/Preview"));
// campaign
const Caja = lazy(() => import("components/caja/Caja"));
const Alcon = lazy(() => import("components/alcon/Alcon"));

const App = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		GAInitialize(Config.GA_ID);
	}, []);

	useEffect(() => {
		GAPageView();
	}, [pathname]);

	return (
		<div>
			<SnackbarProvider maxSnack={3}>
				<Suspense fallback={<Loading />}>
					<BrowserRouter>
						<Switch>
							{/* widget */}
							<Route path="/caja" component={Caja} />
							<Route path="/alcon" component={Alcon} />
							<Route path="/lightBox" component={LightBox} />
							<Route path="/prebuy" component={Prebuy} />
							<Route path="/embed" component={Embed} />
							<Route path="/preview" component={EmbedPreview} />
							{/* 註冊連結 */}
							<Route path="/invite" component={Invite} />
							{/* normal path */}
							<Route path="/login" component={Login} />
							<Route
								path={Paths.CyberbizLogin}
								component={CyberbizLogin}
							/>
							<Route path="/register" component={Register} />
							<Route
								path="/auth"
								component={SocialPlatformAuth}
							/>
							<Route
								path={Paths.CyberbizCallback}
								component={CyberbizCallback}
							/>
							<Route
								path="/resetPassword"
								component={ResetPassword}
							/>
							<Route path="/policy" component={Policy} />
							<Route path="/" component={Navigation} />
						</Switch>
					</BrowserRouter>
				</Suspense>
			</SnackbarProvider>
		</div>
	);
};

export default App;
