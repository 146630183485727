import UserAPI from "services/UserAPI";
import { get } from "lodash-es";
import Helper from "utils/Helper";

export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT = "LOGOUT";

const func = () => {};

/**
 * get user info
 * @param {Object} params
 * @param {function} callback - (data, error)
 * @return {function(*)} - action
 */
export const GetUserInfo =
	(callback = func) =>
	(dispatch) => {
		UserAPI.GetUserInfo()
			.then((data) => {
				const userInfo = {
					...data,
					isSystemAdmin: Helper.ToBool(
						get(data, "permissions.admin", false)
					),
					isAccounting: Helper.ToBool(
						get(data, "permissions.accounting", false)
					),
				};
				callback(userInfo);
				return dispatch({
					type: FETCH_USER,
					payload: userInfo,
				});
			})
			.catch((error) => {
				callback({}, error);
			});
	};

export const UpdateUser =
	(data, callback = func) =>
	(dispatch) => {
		callback(data, null);

		return dispatch({
			type: UPDATE_USER,
			payload: data,
		});
	};

export const Logout =
	(callback = func) =>
	(dispatch) => {
		UserAPI.Logout()
			.then((data) => {
				setTimeout(() => {
					callback(data, null);
				});
				return dispatch({
					type: LOGOUT,
					payload: {},
				});
			})
			.catch((error) => {
				setTimeout(() => {
					callback({}, null);
				});
				return dispatch({
					type: LOGOUT,
					payload: {},
				});
			});
	};
