import Helper from "utils/Helper";
import { FETCH_USER, UPDATE_USER, LOGOUT } from "actions/user";

const userReducer = (state = {}, action) => {
	switch (action.type) {
		case FETCH_USER: {
			try {
				if (!action.payload) return state;
				const { userId } = action.payload;
			} catch (error) {
				Helper.Print("FETCH_USER", error);
			} finally {
				return action.payload;
			}
		}

		case LOGOUT: {
			return {};
		}

		case UPDATE_USER: {
			return {
				...state,
				...action.payload,
			};
		}

		default:
			return state;
	}
};

export default userReducer;
