import { ENV } from "constants/config";
import ReactGA from "react-ga4";
import GAAPI from "services/GAAPI";

export const Category = {
	Campaign: "Campaign",
	MyCampaign: "MyCampaign",
	User: "User",
	Widget: "Widget",
	KYC: "KYC",
	Wallet: "Wallet",
	Email: "Email",
};

export const Action = {
	// Campaign
	ShareCampaign: "share_campaign",
	CheckTimeline: "check_timeline",
	RewardTier: "check_reward_tier",
	CheckPrize: "check_prize",
	CheckContent: "check_campaign_content",
	HowToUseLocationTags: "how_to_use_location_tags",
	ClickSubmitBtn: "click_submit_button",
	SubmitFailed: "submit_failed",
	SubmitSuccess: "submit_success",
	ClickDetailBtn: "click_details",
	// User
	Login: "login",
	CompleteRegistration: "complete_registration",
	ResetPassword: "reset_password",
	AuthSuccess: "authorization_successful",
	AuthFailed: "authorization_failed",
	// KYC
	GoVerify: "go_verify",
	KYCSubmit: "kyc_submit",
	KYCEdit: "kyc_edit",
	// Wallet
	WithdrawalApply: "withdrawal_apply",
	// Widget
	LearnMore: "learn_more",
	ProductReg: "widget_reg",
	ProductCardClick: "click_campaign_card",
	KeepShopping: "click_keep_shopping",
	// Email
	EmailCardClick: "click_email_campaign_card",
};

export const GAInitialize = (trackingID) => {
	if (ENV.isProd) {
		console.log("trackingID", trackingID);
		ReactGA.initialize(trackingID);
	}
};

export const GAPageView = () => {
	if (ENV.isProd) {
		ReactGA.send("pageview");
	}
};

export const GASetUserId = (userId) => {
	if (ENV.isProd) {
		ReactGA.set({ user_id: userId });
	}
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
export const GAEvent = (category, action, label, value) => {
	if (ENV.isProd) {
		ReactGA.event({
			category,
			action,
			label: `${label}`,
			value, // number
		});
	}
};

/**
 * Event - Send Event by API
 * @param {string} category
 * @param {string} action
 */
export const SendEvent = async ({ action, category, ...others }) => {
	if (ENV.isProd) {
		await GAAPI.SendEvent({
			name: action,
			params: {
				category,
				...others,
			},
		});
	}
};
