export const CHANGE_LANG = "CHANGE_LANG";
export const SET_NEW_MSG = "SET_NEW_MSG";

const func = () => {};

export const ChangeLang = (lang) => ({
	type: CHANGE_LANG,
	payload: lang,
});

export const SetNewMsg = (newMsg) => ({
	type: SET_NEW_MSG,
	payload: newMsg,
});
