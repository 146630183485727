import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import general from "./general";
import user from "./user";

const appReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		general,
		user,
	});

const createRootReducer = (history) => (state, action) => {
	return appReducer(history)(state, action);
};

export default createRootReducer;
