import { get, isArray, isObject, replace, isPlainObject } from "lodash-es";
import { Parser } from "@json2csv/plainjs";
import { ENV } from "constants/config";

const func = (props) => props;

const Helper = {
	GetCurrentGeoLocation: () => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const lat = get(position, "coords.latitude", "");
					const lng = get(position, "coords.longitude", "");

					console.log("Latitude is :", lat);
					console.log("Longitude is :", lng);

					resolve({
						lat,
						lng,
					});
				},
				(error) => {
					console.log("getCurrentLocation : ", error);
					reject("存取位置資訊失敗，請確認權限是否開啟。");
				}
			);
		});
	},
	/**
	 * @param {string} title
	 * @param {Array|Object|string} log
	 */
	Print: (title, log = []) => {
		if (ENV.isDev) {
			const logs = isArray(log) ? log : [log];
			console.group(`${title}`);
			logs.forEach((el) => console.log(el));
			console.groupEnd();
			console.log("\n");
		}
	},

	Wait: (milliseconds = 0, callback = func) =>
		new Promise((resolve, reject) => {
			if (milliseconds <= 0) {
				resolve(callback());
				return;
			}
			setTimeout(() => resolve(callback()), milliseconds);
		}),

	ValidateEmailForamt: (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	},
	ValidatePasswordPormat: (password) => {
		const re = /(?=[A-Za-z0-9]+$)^(?=.{8,}).*$/;
		return re.test(String(password));
	},
	ValidateTWID: (id) => {
		const regex = /^[A-Z][1-2]\d{8}$/;
		return regex.test(id);
	},
	ValidatePhoneNumber: (phoneNumber) => {
		const taiwanPhoneRegex = /^09\d{8}$/;
		return taiwanPhoneRegex.test(phoneNumber);
	},
	GetRamdomString: (length = 20) => {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	},
	RemoveUrlParams: (url = "") => {
		if (url.indexOf("?") === -1) {
			return url;
		}
		return url.split("?")[0];
	},
	/**
	 * @param {geo} location.lat
	 * @param {geo} location.lng
	 * @param {string} location.address
	 */
	GetMapQueryUrl: (location = {}) => {
		const { geo = {}, address = "" } = location;
		const { lat = "", lng = "" } = geo;
		if (!lat || !lng) {
			return `https://maps.google.com?q=${address}`;
		}
		return `https://maps.google.com?q=${lat},${lng}`;
	},

	GetMapJourneyUrl: (current = {}, origin = {}, dest = {}) => {
		/* 現在位置 -> 載客點 -> 目的地 */
		return `https://www.google.com/maps/dir/${current.lat},${current.lng}/${origin.geo.lat},${origin.geo.lng}/${dest.geo.lat},${dest.geo.lng}`;
	},

	ToBool: (value) => {
		if (value == false || value === "false" || value === "FALSE") {
			return false;
		}

		if (value == true || value === "true" || value === "TRUE") {
			return true;
		}

		return false;
	},

	ParseJson: (str, fallback = {}) => {
		try {
			if (isObject(str)) {
				throw new Error("value is object");
			}

			const validStr = replace(str, /\bNaN\b/g, "null");

			const json = JSON.parse(validStr);
			const isSameType =
				(isArray(json) && isArray(fallback)) ||
				(isPlainObject(json) && isPlainObject(fallback));

			return isSameType ? json : fallback;
		} catch (error) {
			// 已經是object
			const isSameType =
				(isArray(str) && isArray(fallback)) ||
				(isPlainObject(str) && isPlainObject(fallback));

			return isSameType ? str : fallback;
		}
	},

	/**
	 * 下載CSV
	 * @param {array} data
	 * @param {array} fields
	 * @param {string} fileName
	 * @param {function} callback
	 */
	DownloadCsv: (data, fields, fileName, callback = func) => {
		try {
			const parser = new Parser({ fields, quote: "" });
			const csv = parser.parse(data);
			const BOM = "\uFEFF";
			const csvContent = BOM + csv;

			const csvData = new Blob([csvContent], {
				type: "text/csv;charset=utf-8;",
			});

			const a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(csvData, {
				type: "text/csv;charset=utf-8;",
			});
			a.download = `${fileName}.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);

			callback();
			return { error: null };
		} catch (error) {
			callback(error);
			return { error };
		}
	},
	IsLocalStorageAvailable: () => {
		try {
			// 嘗試存取 localStorage，若有例外，則返回 false
			return !!(
				window.Storage &&
				window.localStorage &&
				window.localStorage instanceof Storage
			);
		} catch (e) {
			return false;
		}
	},
};

export default Helper;
