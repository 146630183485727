import { split, filter, includes, find } from "lodash-es";
import LSKey from "constants/localStorageKey";
import Helper from "utils/Helper";
import { Language } from "constants/general";
import { CHANGE_LANG, SET_NEW_MSG } from "actions/general";

// Languages
let storedLang = Helper.IsLocalStorageAvailable()
	? localStorage.getItem(LSKey.language)
	: "zh"; // 設置預設值為中文

const [browserLang] = split(navigator.language, /[-_]/); // language without region code
const [defaultLang = Language[0]] = filter(Language, (el) => {
	const { value } = el;
	return includes(value, browserLang);
});

const defaultState = {
	lang: storedLang || defaultLang.value,
	hasNewMsg: false,
};

const generalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case CHANGE_LANG: {
			const language =
				find(Language, { value: action.payload }) || Language[0];
			const { value: lang = "en" } = language;

			try {
				if (Helper.IsLocalStorageAvailable()) {
					localStorage.setItem(LSKey.language, lang);
				}
			} catch (error) {
				Helper.Print("CHANGE_LANG", error);
			} finally {
				return {
					...state,
					lang,
				};
			}
		}
		case SET_NEW_MSG: {
			const hasNewMsg = action.payload;
			try {
				if (Helper.IsLocalStorageAvailable()) {
					localStorage.setItem(LSKey.hasNotification, hasNewMsg);
				}
			} catch (error) {
				Helper.Print("SET_NEW_MSG", error);
			} finally {
				return {
					...state,
					hasNewMsg,
				};
			}
		}

		default: {
			return state;
		}
	}
};

export default generalReducer;
