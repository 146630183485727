import { Box, CircularProgress } from "@mui/material";

function Loading() {
	return (
		<Box display="flex" justifyContent="center" pt={5}>
			<CircularProgress size={48} />
		</Box>
	);
}

export default Loading;
